<script setup>
import { ref, onMounted, computed } from "vue";

const props = defineProps({
    article: Object,
});
</script>

<template>
    <div
        :href="article.teaserHref"
        class="card is-press is-no-link"
    >
        <div class="image is-2by1 has-round-corners">
            <a :href="article.teaserHref" class="image-link">
              <figure v-html="article.teaserImage"></figure>
            </a>
          </div>

        <div class="description mt-4">
            <div class="is-regular">
                {{ article.creationDate }} &ndash;
                <span v-for="tag in article.tags">{{ tag }}</span>
            </div>
            <a :href="article.teaserHref" class="heading-5 mt-3">
                {{ article.teaserTitle }}
            </a>
        </div>
    </div>
</template>
