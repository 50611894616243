class Modal {
  constructor() {
      this.buttonList = document.querySelectorAll('[data-modal]');
      this.buttonHrefList = document.querySelectorAll('[href^="#modal-"]');
      this.closeList = document.querySelectorAll('.js-modal-close');
  }

  init() {
    if (this.buttonList.length > 0 || this.buttonHrefList.length > 0) {
          this.bindEvents();
      }
  }

  bindEvents() {

    function stopVideos(videos) {
      videos.forEach(v => {
        v.pause();
      });
    }

    function stopIframes(iframes) {
      iframes.forEach(iframe => {
        iframe.src = iframe.src;
      });
    }

    function deactivateModals(modals) {
      modals.forEach(modal => {
        modal.classList.remove('is-active');
      });
    }
      
    const closeModals = () => {
        const modalContents = document.querySelectorAll('[data-modal-content]');
        const iframes = document.querySelectorAll('.modal iframe');
        const videos = document.querySelectorAll('.modal video');
      
      if (videos.length || iframes.length) {
        if (videos.length) { stopVideos(videos); }
        if (iframes.length) { stopIframes(iframes); }

        window.setTimeout(() => {
          deactivateModals(modalContents);
        }, 500);
      } else {
        deactivateModals(modalContents);
      }
    };

     document.body.addEventListener('click', function (event) {
      let element;
      
      element = event.target;
      
          if (!element.matches('[data-modal]') && !element.matches('[href^="#modal-"]')) {
              let parentElement = element.closest('[data-modal]');

              if (!parentElement) {
                  parentElement = element.closest('[href^="#modal-"]');
              }

              if (parentElement) {
                  element = parentElement;
              }
          }

        if (element && (element.matches('[data-modal]') || element.matches('[href^="#modal-"]'))) {
          const modalContents = document.querySelectorAll('[data-modal-content]');          
              let contentTarget = element.dataset.hasOwnProperty('modal') ? element.dataset.modal : element.getAttribute('href').substr(1);

              modalContents.forEach((content, i) => {
                  if (content.getAttribute('data-modal-content') === contentTarget) {
                    content.classList.add('is-active');

                    if (content.querySelector('video')) {
                      let modalVideo = content.querySelector('video'); 
                      modalVideo.play().catch(e => { console.log(e); });
                    }

                    if (document.querySelector('.video-wrapper video')) {
                      let pageVideo = document.querySelector('.video-wrapper video');
                      setTimeout(() => {
                        pageVideo.pause();
                      }, 500);
                    }
                  } 
              });
          }

          if (element && element.classList.contains('js-modal-close')) {
            closeModals();
          }
      });
  }
}

export default new Modal();
