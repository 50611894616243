<script setup>
import { ref, computed } from "vue";
import Cases from "./Cases.vue";
import Knowledge from "./Knowledge.vue";
import News from "./News.vue";
import Press from "./Press.vue";

const props = defineProps({
    categoryTags: Array,
    preSelected: String,
    uniqueCreationYears: Array,
    initialArticleData: Array,
    teaserTemplate: String,
    parentId: String,
    link: Object,
    locale: Object
});

const activeFilter = ref({ tag: props.preSelected ?? "all", year: "all" });
const articleData = ref(props.initialArticleData);
const isListEnd = ref(false);

const initialItemAmount = props.initialArticleData.length;
const itemsPerPage = 15;
const page = ref(1);
const hasTagFilter = ["cases", "knowledge", "news"];
const hasYearFilter = ["knowledge", "news", "press"];
const hasLink = ['press'];

function tagFilterChange(tag) {
    activeFilter.value.tag = activeFilter.value.tag === tag ? "all" : tag;
}

const activeArticles = computed(() => {
    const filteredArticles = articleData.value.filter((article) => {
        return (
            (activeFilter.value.tag === "all" ||
                article.tags.includes(activeFilter.value.tag)) &&
            (activeFilter.value.year === "all" ||
                article.creationYear === activeFilter.value.year)
        );
    });

    return filteredArticles.slice(0, page.value * itemsPerPage);
});

function handlePagination() {
    page.value++;
    if (needNewArticles()) {
        fetchNewArticles();
    }
}

function needNewArticles() {
    if (page.value * itemsPerPage >= articleData.value.length) return true;
    else false;
}

function fetchNewArticles() {
    const queryParams = new URLSearchParams({
        articlesAmount:
            initialItemAmount + page.value * itemsPerPage + 2 * itemsPerPage,
        parentId: props.parentId ?? window.location.pathname,
        teaserTemplate: props.teaserTemplate,
    });

    fetch(`/ajax/articles?${queryParams}`)
        .then((response) => {
            if (!response.ok) {
                throw new Error("Response not ok");
            }
            return response.json();
        })
        .then((data) => {
            articleData.value = data.articleData;
            isListEnd.value = data.isListEnd;
        })
        .catch((error) => {
            console.error(error);
        });
}
</script>

<template>
    <div class="container">
        <div class="columns is-gapless">
            <div class="column" v-if="hasTagFilter.includes(teaserTemplate)">
                <div class="tag-row mb-5">
                    <span
                        :class="{
                            'anker-link mr-3': true,
                            'is-active': activeFilter.tag == 'all',
                        }"
                        @click="() => tagFilterChange('all')"
                    >
                        {{locale.allFilter}}
                    </span>

                    <span
                        v-for="tag in categoryTags"
                        @click="() => tagFilterChange(tag)"
                        :key="tag"
                        :class="{
                            'anker-link': true,
                            'is-active': activeFilter.tag == tag,
                        }"
                    >
                        {{ tag }}
                    </span>
                </div>
            </div>
            <div
                :class="{ 'column': true, 'is-flex': true, 'is-justify-content-space-between': hasLink.includes(teaserTemplate) }"
                v-if="hasYearFilter.includes(teaserTemplate)"
            >
                <div :class="{ 'select article-select': true, 'margin-left-auto-tablet': hasYearFilter.includes(teaserTemplate) && hasTagFilter.includes(teaserTemplate) }">
                    <select v-model="activeFilter.year">
                        <option value="" disabled selected>
                            Filtern nach Jahr
                        </option>
                        <option value="all">{{locale.allFilter}}</option>
                        <option
                            v-for="year in uniqueCreationYears"
                            :value="year"
                        >
                            {{ year }}
                        </option>
                    </select>
                </div>
                <a v-if="hasLink.includes(teaserTemplate)" class="is-flex is-align-items-center" :href="link.href">{{link.text}}</a>
            </div>
        </div>

        <div class="columns is-multiline">
            <div
                v-for="article in activeArticles"
                :class="{'column is-4 js-filter-item': true, 'pb-4-tablet': teaserTemplate !== 'cases'}"
            >
                <Cases v-if="teaserTemplate === 'cases'" :article="article" :locale="locale.caseCtaText" />
                <News v-if="teaserTemplate === 'news'" :article="article" />
                <Knowledge
                    v-if="teaserTemplate === 'knowledge'"
                    :article="article"
                />
                <Press v-if="teaserTemplate === 'press'" :article="article" />
            </div>
        </div>
        <div class="is-flex is-justify-content-center mt-5">
            <button
                class="button is-dark-outlined is-arrowless"
                @click="handlePagination"
                v-if="!isListEnd && props.initialArticleData.length > itemsPerPage"
            >
                {{ locale.pagginationText }}
            </button>
        </div>
    </div>
</template>
