<script setup>
import { ref, onMounted, computed } from "vue";

const props = defineProps({
    article: Object,
});
</script>

<template>
    <div
        :href="article.teaserHref"
        data-animation=""
        class="card is-no-link"
    >
        <div class="image is-2by1 has-round-corners">
            <a :href="article.teaserHref" class="image-link">
              <figure v-html="article.teaserImage"></figure>
            </a>
          </div>
        

        <div class="description is-transparent mt-4">
            <a :href="article.teaserHref">
                <div class="heading-5 pb-2">{{ article.teaserTitle }}</div>
            </a>
            <div class="">
                {{ article.teaserDescription }}
            </div>
        </div>
    </div>
</template>
