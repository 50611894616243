import { gsap, ScrollTrigger } from "gsap";

class Parallax {
    constructor() {}

    initHeader(trigger, background, ...foregrounds) {
        this.trigger = trigger;
        this.background = background;
        this.foreground = foregrounds;

        if (
            this.validateSelector(this.trigger) &&
            this.validateSelector(this.background)
        ) {

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: this.trigger,
                    start: "top top",
                    end: "bottom top",
                    scrub: true,
                },
            });

            gsap.utils.toArray(this.background).forEach((image) => {
              image.style.willChange = 'transform';
                tl.to(
                    image,
                    {
                        y: image.offsetHeight * 0.15,
                        ease: "none",
                    },
                    0
                );
            });

            if (this.foreground.length) {
                gsap.utils.toArray(this.foreground).forEach((foreground) => {
                    foreground.style.willChange = 'transform';
                    console.log('one')
                    tl.to(
                        foreground,
                        {
                            y: foreground.offsetHeight * 0.75,
                            ease: "none",
                        },
                        0
                    );
                });
            }
        }
    }

    validateSelector(selector) {
      const element = document.querySelector(selector)
        return !!element && !element.hasAttribute('disableAnimation');
    }
}

export default new Parallax();
