<script setup>
import { ref, onMounted, computed } from "vue";

const props = defineProps({
  article: Object,
  locale: String
});
</script>

<template>
  <a :href="article.teaserHref">
    <div class="card is-case is-no-link hover-animation">
        <div>
            <figure class="image is-4by3" v-html="article.teaserImage"></figure>
            <div class="button-container">
                <a class="button" :href="article.teaserHref">{{locale}}</a>
            </div>
        </div>

        <div class="description">
            <div class="heading-5">
                {{ article.teaserTitle }}
            </div>
            <div class="copy">
            {{ article.teaserDescription }}
          </div>
        </div>
    </div>
  </a>
</template>
